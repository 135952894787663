


























































import { User } from "@/types/user";
import Vue from "vue";
import Component from "vue-class-component";
import AvatarCropper from "vue-avatar-cropper";
import auth from "@/store/modules/user";
import AvatarUpload from "@/components/utility/AvatarUpload.vue";

@Component({
  components: {
    AvatarCropper,
    AvatarUpload,
  },
})
export default class Profile extends Vue {
  showCrop = false;

  get authUser(): User | undefined {
    return auth.user;
  }

  get getPicture(): string {
    return this.authUser ? this.authUser.picture : "";
  }

  get uploadURL(): string {
    return process.env.VUE_APP_API_BASE_URL + "profile";
  }

  handleUpload(user: User): void {
    auth.update(user);
  }
}
